import React from 'react';
import { Box } from '@mui/material';
import Lottie from 'react-lottie';
import logoLottieData from 'src/assets/lotties/logo-loader-lottie.json';

const lottieLengthInSecs = 2.5;

export const InitialLoader: React.FC<{
  canBeHidden: boolean;
  setInitialLoaderTransitionEnd: () => void;
}> = ({ canBeHidden, setInitialLoaderTransitionEnd }) => {
  const [secsShown, setSecsShown] = React.useState(0);
  const [isShown, setIsShown] = React.useState(true);
  const [isHidden, setIsHidden] = React.useState(false);

  React.useEffect(() => {
    if (!isShown) return;

    const interval = setInterval(() => {
      setSecsShown((prev) => prev + 0.5);
    }, 500);

    return () => clearInterval(interval);
  }, [isShown]);

  React.useEffect(() => {
    if (canBeHidden && secsShown >= lottieLengthInSecs) {
      setIsShown(false);
    }
  }, [secsShown, canBeHidden]);

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 10000,
        height: `100%`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgb(0,0,0)',
        opacity: isShown ? 1 : 0,
        transition: 'opacity 0.5s',
        visibility: isHidden ? 'hidden' : 'visible',
        top: 0,
        left: 0,
      }}
      onTransitionEnd={() => {
        setIsHidden(true);
        setInitialLoaderTransitionEnd();
      }}
    >
      <Box sx={{ width: '23rem', maxWidth: '100%' }}>
        <Lottie
          options={{
            animationData: logoLottieData,
            loop: false,
          }}
          width={'100%'}
        />
      </Box>
    </Box>
  );
};
