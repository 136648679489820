// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
// import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: 'AIzaSyDnhxegTeKyixUno6ydUNLSYbCpw81mqB8',
  authDomain: 'viralplaceapp.firebaseapp.com',
  projectId: 'viralplaceapp',
  storageBucket: 'viralplaceapp.appspot.com',
  messagingSenderId: '157246347768',
  appId: '1:157246347768:web:7b11edaf36bef0e2ff1e0d',
  measurementId: 'G-1DPBCYDHG0',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LfVj3wpAAAAAKxEAXF1E1UZ7G3Oxwansswu0YFC'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});

// const functions = getFunctions(app);
// connectFunctionsEmulator(functions, '127.0.0.1', 5001);

// Initialize Firebase Authentication and get a reference to the service\
export { appCheck };
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const analytics = getAnalytics(app);
export default app;
