import { MigrationManifest, createMigrate } from 'redux-persist';
import { initialUser } from '../slices/userProfile';
import { RootState } from '../index';
/**
 * Warning: when you changed the existing redux data structure
 * - First, upgrade the version of redux persist
 * - Second, add migration logic here
 */

const MIGRATION_DEBUG = true;

/*
 * Latest version (V1) is simply the currently used redux RootState.
 */
type PersistedRootStateV1 = RootState;

/*
 *  Previous versions for redux, you can add changes for it in here.
 *  In this version we added `user` inf `userProfile` slice when going from V0 to V1,
 */
type PersistRootStateV0 = Omit<PersistedRootStateV1, 'userProfile'> & {
  userProfile: Omit<PersistedRootStateV1['userProfile'], 'user'>;
};

const migrations: MigrationManifest = {
  1: (state): PersistedRootStateV1 | undefined => {
    const { userProfile, ...otherState } = state as PersistRootStateV0;
    return {
      ...otherState,
      userProfile: {
        ...userProfile,
        user: initialUser,
      },
    };
  },
};

export const migrate = createMigrate(migrations, { debug: MIGRATION_DEBUG });
