import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import type { User } from '@auth0/auth0-react';
import type { LanguageType } from '@nexusui/theme';
import { IBasicUser } from '@nexusui/components';

interface ProfileState {
  profile: {};
  user: IBasicUser;
  language: LanguageType;
  theme: 'light' | 'dark';
}
export const initialUser: IBasicUser = {
  id: '',
  firstName: '',
  lastName: '',
  avatar: '',
  email: '',
};
const initialState: ProfileState = {
  profile: {},
  user: initialUser,
  language: 'en-US',
  theme: 'dark',
};

const userProfileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    // updateProfile: (state, action: PayloadAction<User>) => {
    //   state.profile = action.payload;
    // },
    // updateProfileAvatar: (state, action: PayloadAction<string>) => {
    //   state.profile.Avatar = action.payload;
    // },
    changeLanguage: (state, action: PayloadAction<LanguageType>) => {
      state.language = action.payload;
    },
    changeTheme: (state, action: PayloadAction<'light' | 'dark'>) => {
      state.theme = action.payload;
    },
    updateUser: (state, action: PayloadAction<IBasicUser>) => {
      state.user = action.payload;
    },
  },
});

const { actions, reducer } = userProfileSlice;
export const { changeLanguage, changeTheme, updateUser } = actions;
export default reducer;
