import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserProfile } from '@shared/types/user';
import HiddenGmailLogin from 'src/pages/HiddenGmailLogin/HiddenGmailLogin';

const Orders = React.lazy(() => import('src/pages/ClientDashboard'));
// const ClientProfile = React.lazy(() => import('src/pages/ClientProfile'));
const CreateOrder = React.lazy(() => import('src/pages/CreateOrder/CreateOrder'));
const OrderCompletion = React.lazy(() => import('src/pages/OrderCompletion'));
const Notfound = React.lazy(() => import('src/pages/NotFound'));
const Forbidden = React.lazy(() => import('src/pages/Forbidden'));
const Error = React.lazy(() => import('src/pages/Error'));
// const Login = React.lazy(() => import('src/pages/Login'));
// const SignUp = React.lazy(() => import('src/pages/SignUp'));
const AdminDashboard = React.lazy(() => import('src/pages/AdminDashboard'));
const AdminInviteCreator = React.lazy(() => import('src/pages/AdminInviteCreator/AdminInviteCreator'));
const InitialPage = React.lazy(() => import('src/pages/InitialPage'));
const CreatorProfile = React.lazy(() => import('src/pages/CreatorProfile'));
const CreatorDashboard = React.lazy(() => import('src/pages/CreatorDashboard'));
const CreatorMoney = React.lazy(() => import('src/pages/CreatorMoney/CreatorMoney'));
const AdminWithdrawalApproval = React.lazy(() => import('src/pages/AdminWithdrawalApproval'));
const TermsOfService = React.lazy(() => import('src/pages/TermsOfService/TermsOfService'));
const PrivacyPolicy = React.lazy(() => import('src/pages/PrivacyPolicy/PrivacyPolicy'));
const PhoneLogin = React.lazy(() => import('src/pages/PhoneLogin/PhoneLogin'));
const ActiveIncomeInstructions = React.lazy(
  () => import('src/pages/ActiveIncomeInstructions/ActiveIncomeInstructions'),
);
const PassiveIncomeInstructions = React.lazy(
  () => import('src/pages/PassiveIncomeInstructions/PassiveIncomeInstructions'),
);

export const getRoutes = ({
  isAuthenticated,
  userRoles,
  initialLoaderTransitionFinished,
}: {
  isAuthenticated: boolean;
  userRoles?: UserProfile['roles'];
  initialLoaderTransitionFinished: boolean;
}) => {
  const availableRoutes = [
    {
      path: '/create-order',
      element: isAuthenticated ? <CreateOrder /> : <Navigate to="/login" />,
    },
    {
      path: '/login',
      element: !isAuthenticated ? (
        <PhoneLogin initialLoaderTransitionFinished={initialLoaderTransitionFinished} />
      ) : (
        <Navigate to="/" />
      ),
    },

    // NOTE: Semi-backdoor-gmail login
    {
      path: '/gmail-login-hidden',
      element: <HiddenGmailLogin />,
    },

    {
      path: '/forbidden',
      element: isAuthenticated ? <Forbidden /> : <Navigate to="/login" />,
    },
    {
      path: '/error',
      element: isAuthenticated ? <Error /> : <Navigate to="/login" />,
    },
    {
      path: '/notfound',
      element: isAuthenticated ? <Notfound /> : <Navigate to="/login" />,
    },
    {
      path: '/passive-income-instructions',
      element: isAuthenticated ? <PassiveIncomeInstructions /> : <Navigate to="/login" />,
    },
    {
      path: '/unique-video-instructions',
      element: isAuthenticated ? <ActiveIncomeInstructions /> : <Navigate to="/login" />,
    },
    {
      path: '/terms-of-service',
      element: <TermsOfService />,
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicy />,
    },
    {
      path: '',
      element: isAuthenticated ? <InitialPage /> : <Navigate to="/login" />,
    },
    // {
    //   path: '/profile',
    //   element: isAuthenticated ? <ClientProfile /> : <Navigate to="/login" />,
    // },
  ];

  if (userRoles?.isAdmin) {
    availableRoutes.push({
      path: '/admin/dashboard',
      element: isAuthenticated ? <AdminDashboard /> : <Navigate to="/login" />,
    });
    availableRoutes.push({
      path: '/admin/invite-creator',
      element: isAuthenticated ? <AdminInviteCreator /> : <Navigate to="/login" />,
    });
    availableRoutes.push({
      path: '/admin/withdrawal-approval',
      element: isAuthenticated ? <AdminWithdrawalApproval /> : <Navigate to="/login" />,
    });
  }

  if (userRoles?.isCreator) {
    availableRoutes.push({
      path: '/creator/dashboard',
      element: isAuthenticated ? <CreatorDashboard /> : <Navigate to="/login" />,
    });
    availableRoutes.push({
      path: '/creator/profile',
      element: isAuthenticated ? <CreatorProfile /> : <Navigate to="/login" />,
    });
    availableRoutes.push({
      path: '/creator/money',
      element: isAuthenticated ? <CreatorMoney /> : <Navigate to="/login" />,
    });
  } else {
    availableRoutes.push({
      path: '/order-completion',
      element: isAuthenticated ? <OrderCompletion /> : <Navigate to="/login" />,
    });
    availableRoutes.push({
      path: '/orders',
      element: isAuthenticated ? <Orders /> : <Navigate to="/login" />,
    });
  }

  if (!isAuthenticated) {
    availableRoutes.push({
      path: '*',
      element: <Navigate to="/login" />,
    });
  }

  if (isAuthenticated && userRoles) {
    availableRoutes.push({
      path: '*',
      element: <Navigate to="/notFound" />,
    });
  }

  return availableRoutes;
};
