import { Suspense, useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { getRoutes } from 'src/router';
import { useAuth } from 'src/hooks/useAuth';
import { UserProfile } from '@shared/types/user';
import { hotjar } from 'react-hotjar';

import ResponsiveDrawer from 'src/components/AppMenuDrawer/AppMenuDrawer';
import { getFunctions } from 'firebase/functions';
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import firebaseApp, { analytics } from '../firebase';
import LoadingButton from '@mui/lab/LoadingButton';

import './layout.scss';
import { WrappedDialog } from 'src/components/SimpleDialog';
import { TERMS_OF_SERVICE_SRC } from 'src/consts/documents';
import { setUserId, setUserProperties } from 'firebase/analytics';
import { InitialLoader } from 'src/components/InitialLoader/InitialLoader';

import { sha256 } from 'hash.js';

const Router = ({
  isAuthenticated,
  userRoles,
  initialLoaderTransitionFinished,
}: {
  isAuthenticated: boolean;
  userRoles?: UserProfile['roles'];
  initialLoaderTransitionFinished: boolean;
}) => {
  const elements = useRoutes(getRoutes({ isAuthenticated, userRoles, initialLoaderTransitionFinished }));
  return elements;
};

export const Layout = () => {
  const { isAuthenticated, isLoading, profile, userId, isUserLoading, user } = useAuth();
  const [marketingPermissionDialogOpen, setMarketingPermissionDialogOpen] = useState(false);
  const [initialLoaderTransitionFinished, setInitialLoaderTransitionFinished] = useState(false);

  const setInitialLoaderTransitionEnd = () => {
    setInitialLoaderTransitionFinished(true);
  };

  const roles = profile?.roles;
  const isAdmin = profile?.roles.isAdmin;
  const isClient = profile?.roles.isClient;

  useEffect(() => {
    if (!isClient || isAdmin) return;

    hotjar.initialize(3684274, 6);
  }, [isClient, isAdmin]);

  useEffect(() => {
    if (!userId) return;

    setUserId(analytics, userId);
  }, [userId]);

  const tikTokPixel = window.ttq;

  useEffect(() => {
    if (!userId || !user || !profile || !tikTokPixel) return;

    tikTokPixel.identify({
      email: '', // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
      phone_number: sha256().update(user?.phoneNumber).digest('hex'), // string. The phone number of the customer if available. It must be hashed with SHA-256 on the client side.
      external_id: sha256().update(userId).digest('hex'), // string. Any unique identifier, such as loyalty membership IDs, user IDs, and external cookie IDs.It must be hashed with SHA-256 on the client side.
    });
  }, [userId, tikTokPixel, user, profile]);

  useEffect(() => {
    if (!roles) return;

    if (roles.isAdmin) {
      return setUserProperties(analytics, { role: 'admin' });
    }

    if (roles.isCreator) {
      return setUserProperties(analytics, { role: 'creator' });
    }

    if (roles.isClient && !roles.isAdmin) {
      return setUserProperties(analytics, { role: 'client' });
    }
  }, [userId, roles]);

  useEffect(() => {
    if (!isAuthenticated || !profile || profile.hasAcceptedTerms) return;

    setMarketingPermissionDialogOpen(true);
  }, [isAuthenticated, isLoading, profile]);

  return (
    <Box
      component="main"
      data-testid="Layout-Root"
      sx={{
        height: '100%',
        backgroundColor: '#000',
        paddingY: '16px',
        // position: 'relative',
      }}
    >
      <InitialLoader canBeHidden={!isUserLoading} setInitialLoaderTransitionEnd={setInitialLoaderTransitionEnd} />
      {!isUserLoading && (
        <Box sx={{ height: '100%' }}>
          <ResponsiveDrawer isAuthenticated={isAuthenticated} hasUserRoles={!!profile?.roles}>
            <Suspense>
              {profile?.roles ? (
                <Router
                  isAuthenticated={isAuthenticated}
                  userRoles={profile.roles}
                  initialLoaderTransitionFinished={initialLoaderTransitionFinished}
                />
              ) : (
                <Router
                  isAuthenticated={isAuthenticated}
                  initialLoaderTransitionFinished={initialLoaderTransitionFinished}
                />
              )}
            </Suspense>
          </ResponsiveDrawer>
          <MarketingPermissionDialog
            open={marketingPermissionDialogOpen}
            onComplete={() => {
              setMarketingPermissionDialogOpen(false);
            }}
          />
        </Box>
      )}
    </Box>
  );
};

type MarketingPermissionDialogProps = {
  open: boolean;
  onComplete: () => void;
};

const MarketingPermissionDialog = ({ open, onComplete }: MarketingPermissionDialogProps) => {
  const [hasAcceptedMarketing] = useState(false);

  const [requestChanges, isRequestingChanges] = useHttpsCallable<{ hasAcceptedMarketing: boolean }>(
    getFunctions(firebaseApp),
    'acceptTermsAndConditions',
  );

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <WrappedDialog open={open} fullScreen={isMobile} fullWidth={isMobile} containerStyles={{ height: '100%' }}>
      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: '1.25rem',
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          height: '100%',
        }}
      >
        <Typography
          sx={{
            color: '#1C1C1D',
            fontSize: '1.5rem',
            fontWeight: 500,
            textAlign: isMobile ? 'center' : 'left',
          }}
        >
          Regulamin platformy
        </Typography>
        <Typography
          sx={{
            color: '#1C1C1D',
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '140%',
            textAlign: isMobile ? 'center' : 'left',
          }}
        >
          Aby kontynuować musisz zaakceptować warunki współpracy,
          <br /> które znajdują się poniżej
        </Typography>
        <iframe
          src={TERMS_OF_SERVICE_SRC}
          title="Regulamin Viral Place"
          width="100%"
          height="250px"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            overflow: 'visible',
            marginTop: '0.5rem',
            marginBottom: '0.5rem',
            borderRadius: '0.625rem',
            flex: 1,
            border: '1px solid rgba(28, 28, 29, 0.10)',
          }}
        ></iframe>
        <LoadingButton
          loading={isRequestingChanges}
          sx={{
            borderRadius: '1.25rem',
            padding: '0.75rem',
            textTransform: 'none',

            '&:disabled': {
              opacity: '0.2',
              backgroundColor: '#606069',
            },
          }}
          variant="contained"
          onClick={() => {
            requestChanges({ hasAcceptedMarketing });
            onComplete();
          }}
          fullWidth
        >
          Akceptuję Regulamin
        </LoadingButton>
      </Box>
    </WrappedDialog>
  );
};
