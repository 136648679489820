export const PRIVATE_POLICY_SRC =
  'https://docs.google.com/document/d/e/2PACX-1vTeHyrc_7S2Sib1gUxq4Qsu2WNJoreL6LJMigZ3D1rQSK6hyoPla4jwC0u1yXRv6LJS1v7hu28kkwgc/pub?embedded=true';

export const TERMS_OF_SERVICE_SRC =
  'https://firebasestorage.googleapis.com/v0/b/viralplaceapp.appspot.com/o/app_content%2FRegulaminUpdated.html?alt=media&token=801db9a6-57dc-42f2-b655-adf417440a20';

export const PASSIVE_INCOME_INSTRUCTIONS_SRC =
  'https://firebasestorage.googleapis.com/v0/b/viralplaceapp.appspot.com/o/app_content%2FPasywnyZarobekk.pdf?alt=media&token=415714b8-3736-48fb-9351-a53eeb0cd314';

export const ACTIVE_INCOME_INSTRUCTIONS_SRC =
  'https://firebasestorage.googleapis.com/v0/b/viralplaceapp.appspot.com/o/app_content%2FSingleBuyer.pdf?alt=media&token=bcb4f58c-9c2f-49c9-9b3d-d04980078648';
