import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import logo from 'src/assets/logo.svg';
import { useNavigate, NavLink, Link, useMatch } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';

import PaidIcon from '@mui/icons-material/Paid';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { Button, Typography } from '@mui/material';
import { useAuth } from 'src/hooks/useAuth';
import { Close } from '@mui/icons-material';
import SchoolIcon from '@mui/icons-material/School';
import { ReactComponent as DiscordFullIcon } from '../../assets/discord-full-icon.svg';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import { useEventTracker } from 'src/hooks/useEventTracker';

const drawerWidth = 240;

export const HEADER_FULL_HEIGHT = 80;

type ResponsiveDrawerProps = {
  children: React.ReactNode;
  isAuthenticated: boolean;
  hasUserRoles: boolean;
};

export default function ResponsiveDrawer(props: ResponsiveDrawerProps) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { user } = useAuth();
  const isCreateOrderPage = useMatch('/create-order');
  // const isTermsOfServicePage = useMatch('/terms-of-service');
  // const isPrivacyPolicyPage = useMatch('/privacy-policy');

  const { profile } = useAuth();

  const navigate = useNavigate();

  const onSignOut = () => {
    signOut(auth);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prev) => !prev);
  };

  const { trackEvent } = useEventTracker();

  const LogoComponent = (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img
        style={{ cursor: 'pointer', height: '2.2rem', flex: 1 }}
        src={logo}
        alt="ViralPlace"
        onClick={() => navigate('/')}
      />
    </Box>
  );

  const HelpButton = (
    <Button
      variant="contained"
      onClick={() => {
        (window as unknown as { zE: (a: string, b: string) => void }).zE('webWidget', 'show');
        (window as unknown as { zE: (a: string, b: string) => void }).zE('webWidget', 'open');
        // (window as unknown as ZenDesk).zE &&
        (window as unknown as { zE: (a: string, b: string, props: { email?: string | null }) => void })!.zE!(
          'webWidget',
          'identify',
          {
            email: user?.email,
          },
        );
      }}
      sx={{
        display: 'flex',
        height: '2.5rem',
        padding: '0.5rem 1rem',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '0.5rem',
        borderRadius: '1.875rem',
        backgroundColor: '#0F65E5',
        color: '#FFF',
        textAlign: 'center',
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '130%',
        textTransform: 'none',
        boxShadow: 'none',
        width: 'auto',

        '&:hover': {
          backgroundColor: '#0F65E5',
          boxShadow: 'none',
        },
      }}
    >
      Pomoc
    </Button>
  );

  const creatorUpperMenu = React.useMemo(
    () => [
      {
        label: 'Twoje Zamówienia',
        icon: <DashboardIcon />,
        path: '/creator/dashboard',
      },
      {
        label: 'Pięniądze',
        icon: <PaidIcon />,
        path: '/creator/money',
      },
      {
        label: 'Ustawienia Konta',
        icon: <PersonIcon />,
        path: '/creator/profile',
      },
    ],
    [],
  );

  const creatorLowerMenu = React.useMemo(
    () => [
      {
        label: 'Akademia',
        icon: <SchoolIcon />,
        url: 'https://academy.viralplace.io/',
      },
      {
        label: 'Instrukcja Użytkowania',
        icon: <SlideshowIcon />,
        url: 'https://youtu.be/diOlY2dl87s',
      },
      {
        label: 'Metody Zarabiania',
        icon: <PriceChangeIcon />,
        url: 'https://youtu.be/N2jE8OHKx1I',
      },
    ],
    [],
  );

  const clientUpperMenu = React.useMemo(
    () => [
      {
        label: 'Twoje oferty',
        icon: <DashboardIcon />,
        path: '/orders',
      },
      {
        label: 'Opublikuj ofertę',
        icon: <AddToPhotosIcon />,
        path: '/create-order',
      },
    ],
    [],
  );

  const adminUpperMenu = React.useMemo(
    () => [
      {
        label: 'Admin Dashboard',
        icon: <DashboardIcon />,
        path: '/admin/dashboard',
      },
      {
        label: 'Invite Creator',
        icon: <PersonAddIcon />,
        path: '/admin/invite-creator',
      },
      {
        label: 'Approve Withdrawals',
        icon: <AssignmentTurnedInIcon />,
        path: '/admin/withdrawal-approval',
      },
    ],
    [],
  );

  const lowerMenu = React.useMemo(
    () => [
      {
        label: 'Wyloguj',
        icon: <LogoutIcon />,
        onClick: onSignOut,
      },
    ],
    [],
  );

  const isAuthorized = props.hasUserRoles && props.isAuthenticated;

  const drawer = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.09)',
      }}
    >
      <Box>
        {/* <Toolbar /> */}
        {/* {LogoComponent} */}
        {/* <Divider /> */}
        <List>
          {profile?.roles.isCreator &&
            creatorUpperMenu.map(({ label, icon, path }) => (
              <DrawerLink key={label} label={label} icon={icon} path={path} handleDrawerClose={handleDrawerClose} />
            ))}

          {profile?.roles.isClient &&
            clientUpperMenu.map(({ label, icon, path }) => (
              <DrawerLink key={label} label={label} icon={icon} path={path} handleDrawerClose={handleDrawerClose} />
            ))}

          {profile?.roles.isAdmin && (
            <>
              <Divider />
              {adminUpperMenu.map(({ label, icon, path }) => (
                <DrawerLink key={label} label={label} icon={icon} path={path} handleDrawerClose={handleDrawerClose} />
              ))}
            </>
          )}
        </List>
        {profile?.roles.isCreator && (
          <>
            <Divider />
            {creatorLowerMenu.map(({ label, icon, url }) => (
              <ListItem
                key={label}
                disablePadding
                sx={{
                  color: 'rgba(255,255,255,0.75)',
                  ':hover': {
                    backgroundColor: 'rgba(255,255,255,0.1)',
                  },
                }}
              >
                <Link
                  to={url}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    display: 'flex',
                    gap: '0.5rem',
                    padding: '0.5rem 1rem',
                    alignItems: 'center',
                    textDecoration: 'none',
                    color: 'rgba(255,255,255,0.75)',
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 'unset', color: 'rgba(255,255,255,0.75)' }}>{icon}</ListItemIcon>
                  <ListItemText primary={label} />
                </Link>
              </ListItem>
            ))}
          </>
        )}
        <Divider />
        {lowerMenu.map(({ label, icon, onClick }) => (
          <ListItem key={label} disablePadding sx={{ color: 'rgba(255,255,255,0.75)' }}>
            <ListItemButton
              onClick={() => {
                handleDrawerClose();
                onClick();
              }}
              sx={{ gap: 0.5 }}
            >
              <ListItemIcon sx={{ minWidth: 'unset', color: 'rgba(255,255,255,0.75)' }}>{icon}</ListItemIcon>
              <ListItemText primary={label} />
            </ListItemButton>
          </ListItem>
        ))}
      </Box>
      <Box sx={{ padding: '1rem' }}>
        {profile?.roles.isCreator && (
          <Link
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              backgroundColor: '#5662F6',
              width: '100%',
              padding: '0.5rem',
              borderRadius: '0.5rem',
            }}
            target="_blank"
            rel="noreferrer"
            to={'https://discord.gg/gYrdSbNU7u'}
          >
            <DiscordFullIcon style={{ height: '24px' }} />
          </Link>
        )}
        {/* {!isMobile && HelpButton} */}
        <Typography align="center" variant="body1" sx={{ textDecoration: 'underline', cursor: 'pointer', mt: 1 }}>
          <Link style={{ color: 'white' }} target="_blank" rel="noreferrer" to={'/privacy-policy'}>
            Polityka Prywatności
          </Link>
        </Typography>
        <Typography align="center" variant="body1" sx={{ textDecoration: 'underline', cursor: 'pointer', mt: 1 }}>
          <Link style={{ color: 'white' }} target="_blank" rel="noreferrer" to={'/terms-of-service'}>
            Regulamin
          </Link>
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
      }}
    >
      {isAuthorized && !isCreateOrderPage && (
        <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', md: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', md: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          width: { md: isAuthorized ? `calc(100% - ${drawerWidth}px)` : '100%' },
        }}
      >
        <AppBar
          position="static"
          sx={{
            height: '80px',
            backgroundColor: 'unset',
            boxShadow: 'none',
            backgroundImage: 'none',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: { xs: '0 1rem', md: '0' },
              height: '100%',
              width: { xs: '100%', md: '45rem' },
            }}
          >
            {isCreateOrderPage ? (
              <Typography
                sx={{
                  color: '#fff',
                  fontSize: '1.5rem',
                  fontWeight: 600,
                  lineHeight: '1.5rem',
                }}
              >
                Tworzenie oferty
              </Typography>
            ) : (
              LogoComponent
            )}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.75 }}>
              <>
                {!isCreateOrderPage && HelpButton}
                {isAuthorized &&
                  (isCreateOrderPage ? (
                    <IconButton
                      sx={{
                        width: '2.5rem',
                        height: '2.5rem',
                        backgroundColor: 'rgba(255, 255, 255, 0.15)',
                        color: 'white',
                      }}
                      onClick={() => {
                        trackEvent('stopCreateOrder');
                        navigate('/orders');
                      }}
                    >
                      <Close />
                    </IconButton>
                  ) : (
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                      sx={{ height: '3rem', width: '3rem', display: { md: 'none' } }}
                    >
                      <MenuIcon />
                    </IconButton>
                  ))}
              </>
            </Box>
          </Box>
        </AppBar>
        <Box
          sx={{
            minHeight: `calc(100% - ${HEADER_FULL_HEIGHT}px)`,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {props.children}
        </Box>
      </Box>
    </Box>
  );
}

type DrawerLinkProps = {
  label: string;
  icon: React.ReactNode;
  path: string;
  handleDrawerClose: () => void;
};

export const DrawerLink = ({ label, icon, path, handleDrawerClose }: DrawerLinkProps) => (
  <ListItem key={label} disablePadding sx={{ color: '#fff' }}>
    <NavLink
      to={path}
      // className={({ isActive, isPending }) => (isPending ? 'pending' : isActive ? 'active' : '')}
      style={({ isActive }) => ({
        textDecoration: 'none',
        color: isActive ? '#0F65E5' : 'rgba(255,255,255,0.75)',
        backgroundColor: isActive ? '#fff' : 'transparent',
        width: '100%',
      })}
      onClick={handleDrawerClose}
    >
      {({ isActive }) => (
        <ListItemButton sx={{ gap: 0.5 }}>
          <ListItemIcon sx={{ minWidth: 'unset', color: isActive ? '#0F65E5' : 'rgba(255,255,255,0.75)' }}>
            {icon}
          </ListItemIcon>
          <ListItemText primary={label} />
        </ListItemButton>
      )}
    </NavLink>
  </ListItem>
);
