import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogProps, SxProps, Typography, useTheme } from '@mui/material';
import { FC } from 'react';

interface SimpleDialogProps {
  open: undefined | string | boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

/**
 * @deprecated Use [HeaderedDialog] instead. This component will be removed in future releases.
 */
export const SimpleDialog: FC<SimpleDialogProps> = ({ onClose, open, children }) => {
  const theme = useTheme();

  return (
    <Dialog
      PaperProps={{ sx: { backgroundColor: theme.palette.background.default, overflow: 'hidden' } }}
      onClose={onClose}
      open={!!open}
    >
      {children}
    </Dialog>
  );
};

interface WrappedDialogProps {
  open: undefined | string | boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  title?: string;
  containerStyles?: SxProps;
}

export const WrappedDialog: FC<WrappedDialogProps & DialogProps> = ({
  onClose,
  open,
  children,
  title,
  containerStyles,
  ...props
}) => (
  <Dialog
    PaperProps={{ sx: { overflow: 'hidden', backgroundColor: 'rgba(0,0,0,0)', maxWidth: 'unset' } }}
    slotProps={{
      backdrop: {
        sx: {
          backgroundColor: 'rgba(28, 28, 29, 0.95)',
        },
      },
    }}
    PaperComponent={Box}
    onClose={onClose}
    open={!!open}
    {...props}
  >
    {onClose && (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0',
          mb: '1.25rem',
        }}
      >
        <Typography
          sx={{
            color: '#fff',
            fontSize: { xs: '1.5rem', md: '2rem' },
            fontWeight: 600,
            lineHeight: '1.5rem',
            flex: 1,
          }}
        >
          {title}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#3E3E3F',
            borderRadius: '50%',
            width: '2.5rem',
            height: '2.5rem',
            cursor: 'pointer',
          }}
        >
          <Close onClick={onClose} />
        </Box>
      </Box>
    )}
    <Box sx={{ overflow: 'auto', borderRadius: '1rem', ...containerStyles }}>{children}</Box>
  </Dialog>
);
