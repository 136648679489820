import React from 'react';
import { EmptyState } from '@nexusui/components';
import { Box, Button, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

const Error: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ height: 'calc(100vh - 64px)' }}>
      <EmptyState
        icon={<ErrorOutlineOutlinedIcon sx={{ color: 'grey.300', fontSize: 72 }} />}
        header="Oops, something went wrong"
        actions={[
          <Button
            component={Link}
            variant={'outlined'}
            color={'secondary'}
            endIcon={<ArrowForwardOutlinedIcon />}
            onClick={() => navigate(0)}
          >
            Reload
          </Button>,
        ]}
      />
    </Box>
  );
};

export default Error;
