import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserRoleOption as Role } from '@nexusui/components';

interface OrgsState {
  roles: Role[];
}

const initialState: OrgsState = {
  roles: [],
};

const orgsSlice = createSlice({
  name: 'orgs',
  initialState,
  reducers: {
    updateRoles: (state, action: PayloadAction<Role[]>) => {
      state.roles = action.payload;
    },
  },
});

const { actions, reducer } = orgsSlice;
export const { updateRoles } = actions;
export default reducer;
