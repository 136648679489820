import { memo } from 'react';
// import frLocale from 'date-fns/locale/fr';
// import itLocale from 'date-fns/locale/it';
// import deLocale from 'date-fns/locale/de';
// import enUSLocale from 'date-fns/locale/en-US';
// import enGBLocale from 'date-fns/locale/en-GB';
// import { LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ThemeProvider, StyledEngineProvider, Fade, CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';
// import { useSelector } from 'react-redux';
// import { RootState } from './redux';
import { Layout } from './layout/Layout';
import { SnackbarProvider } from 'notistack';
import SnackbarCloseButton from './components/Notification/SnackbarCloseButton';
import Error from './pages/Error';
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './AppInsights';

const myTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#EF4D2A',
      light: '#A3A3A3',
    },
    secondary: {
      main: '#9B9B9B',
      dark: '#606069',
      light: '#fff',
    },
    background: {
      default: '#000',
      paper: '#1C1C1D',
    },
    alternate: {
      main: '#333333',
    },
    text: {
      primary: '#fff',
      secondary: '#A3A3A3',
      disabled: '#636262',
    },
    info: {
      main: '#0F65E5',
      light: '#6fa8dc',
      dark: '#0043a6',
      contrastText: 'rgba(28, 28, 29, 1)',
    },
    divider: '#606069',
  },
  typography: {
    fontFamily: 'Inter',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '1.25rem',
    },
  },
  spacing: 16,
});

const App = memo(() => (
  <StyledEngineProvider injectFirst>
    <AppInsightsContext.Provider value={reactPlugin}>
      <ThemeProvider theme={myTheme}>
        <CssBaseline />
        <SnackbarProvider
          maxSnack={5}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={6000}
          TransitionComponent={Fade}
          hideIconVariant
          action={(snackbarId) => <SnackbarCloseButton snackbarId={snackbarId} />}
        >
          <AppInsightsErrorBoundary onError={() => <Error />} appInsights={reactPlugin}>
            <Layout />
          </AppInsightsErrorBoundary>
        </SnackbarProvider>
      </ThemeProvider>
    </AppInsightsContext.Provider>
  </StyledEngineProvider>
));

export default App;
