import { Button } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';

type Props = {
  snackbarId: SnackbarKey;
};

export default function SnackbarCloseButton({ snackbarId }: Props) {
  const { closeSnackbar } = useSnackbar();
  return (
    <Button color="inherit" size="small" onClick={() => closeSnackbar(snackbarId)}>
      OK
    </Button>
  );
}
