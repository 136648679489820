import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../firebase';
import notextLogo from 'src/assets/notext_logo.svg';
import { useTranslation } from 'react-i18next';
import googleLogoIcon from 'src/assets/google_logo_icon.svg';
import { useSnackbar } from 'notistack';
import { Loader } from 'src/components/Loader';

export default function HiddenGmailLogin() {
  const theme = useTheme();
  const { t } = useTranslation();
  const googleProvider = new GoogleAuthProvider();
  const [isLoading, setIsLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleGoogleSignIn = async () => {
    setIsLoading(true);
    signInWithPopup(auth, googleProvider)
      .catch((error) => {
        const errorMessage = error.message;
        enqueueSnackbar(`Couldn\t create an account. ${errorMessage}`, { variant: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');
    if (!email || !password) {
      setIsLoading(false);
      return;
    }

    await signInWithEmailAndPassword(auth, email.toString(), password.toString())
      .catch((error) => {
        const errorMessage = error.message;
        enqueueSnackbar(`Nie udało się zalogować. ${errorMessage}`, { variant: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Grid
      container
      component="main"
      sx={{
        height: '100%',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexWrap: 'nowrap',
        flex: 1,
      }}
    >
      {isLoading && (
        <Box
          sx={{ width: '100%', height: '100%', position: 'absolute', backgroundColor: 'rgba(0,0,0,0.6)', zIndex: 100 }}
        >
          <Loader />
        </Box>
      )}
      <CssBaseline />
      <Grid item component={Paper} elevation={6} square sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            backgroundColor: theme.palette.background.default,
            padding: 1,
          }}
        >
          <img src={notextLogo} alt="Logotype" />
          <Typography align="center" variant="h1" sx={{ mt: 1 }}>
            {t('Witamy w ')}
            <span style={{ color: theme.palette.primary.main }}>
              Viral
              <span style={{ fontWeight: 400 }}>Place</span>
            </span>
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              mt: 1,
              [theme.breakpoints.up('md')]: {
                width: '31rem',
              },
            }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Hasło"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" /> */}
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 1, mb: 1.5, p: 1 }}>
              Zaloguj się
            </Button>
            <Typography align="center" variant="body1">
              lub
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1.5 }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#fff',
                  p: 1,
                  color: '#333333',
                  textTransform: 'none',
                  fontSize: '0.875rem',
                  // width: '48%',
                  '&:hover': {
                    backgroundColor: '#fff',
                  },
                }}
                onClick={handleGoogleSignIn}
              >
                <img src={googleLogoIcon} alt="google logo icon" style={{ marginRight: '0.5rem' }} />
                Kontynuuj w Google
              </Button>
            </Box>
          </Box>
          <Typography
            align="center"
            variant="body1"
            sx={{ position: 'absolute', textDecoration: 'underline', bottom: '1rem', cursor: 'pointer' }}
          >
            <Link style={{ color: 'white' }} target="_blank" rel="noreferrer" href={'/privacy-policy'}>
              Polityka Prywatności
            </Link>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
