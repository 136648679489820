import { logEvent } from 'firebase/analytics';
import { analytics } from 'src/firebase';
import { useAuth } from './useAuth';
import { useCallback } from 'react';

export const useEventTracker = () => {
  const { profile } = useAuth();

  const roles = profile?.roles;
  const isAdmin = profile?.roles.isAdmin;
  const isClient = profile?.roles.isClient;

  const trackEvent = useCallback(
    (eventName: string) => {
      if (roles && (!isClient || isAdmin)) return;

      logEvent(analytics, eventName);
      window.ttq?.track(eventName);
      window.fbq?.('trackCustom', eventName);
    },
    [roles, isClient, isAdmin],
  );

  return { trackEvent };
};
