import { User, onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { auth, firestore } from '../firebase';
import { ClientProfile, UserProfile } from '@shared/types/user';
import {
  doc,
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  setDoc,
  SnapshotOptions,
} from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';

const profileConverter: FirestoreDataConverter<UserProfile> = {
  toFirestore(profile: UserProfile): DocumentData {
    return profile;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): UserProfile {
    const data = snapshot.data(options);
    return (
      ({
        ...data,
      } as UserProfile) ?? {}
    );
  },
};

const useProfile = (userId?: string) => {
  const [isWaitingForUserId, setIsWaitingForUserId] = useState(!userId);

  const [data, loading, error] = useDocumentData(
    userId ? doc(firestore, 'users', userId).withConverter(profileConverter) : undefined,
  );

  // NOTE: Without this useEffect, the hook would return loading=false for 1 iteration when userId arrives
  // the first time
  useEffect(() => {
    if (!userId) {
      return;
    }

    setIsWaitingForUserId(false);
  }, [userId]);

  return { profile: data, isProfileLoading: loading || isWaitingForUserId, error };
};

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const userId = user?.uid;

  const { profile, isProfileLoading } = useProfile(userId);

  useEffect(() => {
    onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        setIsAuthenticated(true);
        setUser(firebaseUser);
      } else {
        setIsAuthenticated(false);
        setUser(null);
      }
      setIsUserLoading(false);
    });
  }, []);

  return {
    isAuthenticated,
    isUserLoading,
    isLoading: isUserLoading,
    user,
    userId,
    profile,
    isProfileLoading,
  };
};

export const useClientProfileActions = (userId: string) => {
  const updateClientProfile = async (clientProfile: Partial<ClientProfile>) => {
    const clientRef = doc(firestore, 'clients', userId);

    return setDoc(clientRef, clientProfile, { merge: true });
  };

  return { updateClientProfile };
};
