import React from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import { Box, SxProps, Theme, Typography, useTheme } from '@mui/material';
import Lottie from 'react-lottie';
import loaderLottieData from 'src/assets/lotties/2-phase-logo-loader-lottie.json';

export const Loader: React.FC<
  CircularProgressProps & {
    containerProps?: SxProps<Theme>;
    size?: number;
    text?: string | React.ReactNode;
    loaderColor?: string;
  }
> = (props) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: `100%`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'unset',
        ...props.containerProps,
      }}
    >
      <CircularProgress size={props.size ?? 104} thickness={2} sx={{ color: props.loaderColor }} {...props} />

      <Typography
        sx={{
          mt: 1,
          mb: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          color: props.loaderColor ?? theme.palette.primary.main,
        }}
        variant="body"
      >
        {props.text}
      </Typography>
    </Box>
  );
};

export const FullPageLoader: React.FC<{ text?: string | React.ReactNode }> = ({ text }) => (
  <Box
    sx={{
      position: 'absolute',
      zIndex: 10000,
      height: `100%`,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgb(0,0,0)',
      transition: 'backgroundColor 0.5s',
      top: 0,
      left: 0,
    }}
  >
    <Box sx={{ width: '23rem', maxWidth: '100%' }}>
      <Lottie
        options={{
          animationData: loaderLottieData,
          loop: true,
        }}
        width={'100%'}
      />
      <Typography
        sx={{
          color: 'white',
          textAlign: 'center',
          top: '-1rem',
          position: 'relative',
          fontWeight: 500,
          fontSize: '1.125rem',
        }}
      >
        {text}
      </Typography>
    </Box>
  </Box>
);
