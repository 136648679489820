// import enUS from './en-US.json';
// import deDE from './de-DE.json';
// import frFR from './fr-FR.json';
import enGB from './en-GB.json';
// import itIT from './it-IT.json';
// import esES from './es-ES.json';
// import ptBR from './pt-BR.json';
// import zhCN from './zh-CN.json';
// import zhTW from './zh-TW.json';
// import ko from './ko.json';
// import ja from './ja.json';

export const resources: Record<string, any> = {
  // 'en-US': {
  //   translation: enUS,
  // },
  // 'de-DE': {
  //   translation: deDE,
  // },
  // 'fr-FR': {
  //   translation: frFR,
  // },
  'en-GB': {
    translation: enGB,
  },
  // 'it-IT': {
  //   translation: itIT,
  // },
  // 'es-ES': {
  //   translation: esES,
  // },
  // 'pt-BR': {
  //   translation: ptBR,
  // },
  // 'zh-CN': {
  //   translation: zhCN,
  // },
  // 'zh-TW': {
  //   translation: zhTW,
  // },
  // ko: {
  //   translation: ko,
  // },
  // ja: {
  //   translation: ja,
  // },
};
