import { combineReducers } from 'redux';
import userProfileReducer from '../slices/userProfile';
import rolesReducer from '../slices/roles';

export const rootReducer = combineReducers({
  userProfile: userProfileReducer,
  roles: rolesReducer,
});

export type RootReducer = ReturnType<typeof rootReducer>;
