import { useNavigate, NavigateFunction } from 'react-router-dom';

// export a global navigate
// https://stackoverflow.com/questions/68399876/how-to-navigate-outside-of-react-component-using-react-router-6/74159445
// eslint-disable-next-line import/no-mutable-exports
export let globalNavigate: NavigateFunction;

export const GlobalHistory = () => {
  globalNavigate = useNavigate();

  return null;
};
